import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { GetCertificateComponent } from '../get-certificate/get-certificate.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewComponent implements OnInit {
  conference
  starsCount = 3
  public submitted = false;
  public form:FormGroup;

  constructor( public dialogRef: MatDialogRef<GetCertificateComponent>,private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,private service:AppService,
    @Inject(MAT_DIALOG_DATA) public data) {

  }

  ngOnInit(): void {
    console.log(this.data)
    console.log(this.data)
    console.log(this.data)
    this.conference = this.data['conference']
    this.form = this.formbuilder.group({
      text:['',Validators.required],
    })
    // console.log(this.options)
    // console.log(this.options.initialState)
  }
  save(text,starsCount){
    console.log(text)
    console.log(starsCount)
  }





    get f() {return this.form.controls}
    submit(){
      this.submitted = true;
      if (this.form.invalid) {
        // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        return
      }
      const form = {
        rate : this.starsCount,
        text : this.form.value.text,
        conferences_seminars_id : this.conference.id,
        email: this.data.email,
        // ...this.form.value
      }
      this.service.addFeedback(form).pipe(map(res=>res['data'])).subscribe(res=>{
        console.log(res)

        // this.dialogRef.close()
      })
      // alert(JSON.stringify({...this.form.value}))
    }
}
