import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  subject$ = new Subject();

  constructor(private http:HttpClient) { }
  index(){
    return this.http.get(`${environment.apiUrl}/home`)
  }
  pageDataByKey(key){
    return this.http.get(`${environment.apiUrl}/mainDataByKey/${key}`)
  }
  conferences(with_paginate = 'yes',is_active = true,conferences_time = null){
    //
    var url:any = new URL(`${environment.apiUrl}/conferences_seminars?with_paginate=${with_paginate}&is_active=${is_active}`);

    if(conferences_time){
      url.searchParams.append('conferences_time', conferences_time = conferences_time);
    }

    console.log(url)
    console.log(url)
    console.log(url)
    console.log(url)
    console.log(url)
    return this.http.get(url.href)
  }
  conferenceShowById(conferenceId){
    return this.http.get(`${environment.apiUrl}/conferences_seminars/get/${conferenceId}/info`)
  }
  getConferencesSeminarsWithFields(conferenceId){
    return this.http.get(`${environment.apiUrl}/ConferencesSeminars/${conferenceId}/getConferencesSeminarsWithFields`)
  }
  checkConferenceJoinEmail(email,conferenceId){
    return this.http.post(`${environment.apiUrl}/ConferencesSeminars/checkRegistrationEmail`,{
        "registration_email": email,
         "conferences_seminars_id":conferenceId
    })
  }
  conferenceJoin(form,conferenceId,registrationEmail){
    const formData:FormData = new FormData()
    formData.append('conferences_seminars_id', conferenceId)


    formData.append('specialty_id', localStorage.getItem('specialization_id'))
    formData.append('type_of_attend', localStorage.getItem('attend_type'))

    formData.append('registration_email', registrationEmail)

    for (const [key, value] of Object.entries(form)) {
      formData.append(key, `${value}`)

      if(typeof value == 'string'){
        formData.append('names_input[]', key)
      }
      if(typeof value == 'object'){
        formData.append('names_file_input[]', key)
      }
    }
    formData.append('object_values', JSON.stringify(form))
    return this.http.post(`${environment.apiUrl}/ConferencesSeminarsJoin`,formData)
  }
  conferencePay(registration_email:any,conferenceId:any,reg_id:any){

    return this.http.post(`${environment.apiUrl}/payments/code`,{
        "registration_email": registration_email,
        "registration_id": reg_id,
         "conferences_seminars_id":conferenceId
    })
  }
  generateCertificate(conferences_seminars_member_id,conferences_seminars_id,send_to_mail = `yes`){

    return this.http.post(`${environment.apiUrl}/ConferencesSeminars/generateCertificates?send_to_mail=${send_to_mail}`,{
      "conferences_seminars_member_id": conferences_seminars_member_id,
       "conferences_seminars_id":conferences_seminars_id
  })
  }
  detailsCertificate(registration_email,code,conferences_seminars_id,operation_type = 'view',send_to_mail = `no`){
    return this.http.post(`${environment.apiUrl}/ConferencesSeminars/generateCertificatesNew`,{
      "registration_email": registration_email,
       "code":code,
       "conferences_seminars_id":conferences_seminars_id,
        "operation_type":operation_type
  })
  }

  addFeedback(form){
    return this.http.post(`${environment.apiUrl}/feedback`,form)
  }
}
