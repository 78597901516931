import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { GetCertificateComponent } from '../get-certificate/get-certificate.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
// import { fromPath } from "pdf2pic";
// import { saveAs } from "file-saver";
// import { PDFJSStatic } from 'pdfjs-dist';
// const PDFJS: PDFJSStatic = require('pdfjs-dist');
// import * as PDFJSStatic from 'pdfjs-dist';
// import { PDFDocument } from 'pdf-lib';
// import { PDFDocument } from 'pdf-lib';
// import { PDFDocumentProxy } from 'pdfjs-dist';
// import { PDFPageProxy } from 'pdfjs-dist';

// import * as pdfjsLib from 'pdfjs-dist';

declare var PDFJSStatic: any;

// PDFJS.getDocument('helloworld.pdf').then(console.log);

// import PSPDFKit from "pspdfkit";
// import * as PDFJS from 'pdfjs'  ;
// import * as pdfjsLib from 'pdf-lib'  ;
// DFJS.getDocument
// import { PDFDocument } from 'pdf-lib';
// import * as PDFJS from 'pdfjs';
// pdfjsLib
// import { PDFDocument } from 'pdf-lib';
// fs
// import * as fs from 'fs';
// PDFJSStatic.GlobalWorkerOptions.workerSrc  = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJSStatic.version}/pdf.worker.js`;


@Component({
  selector: 'app-view-certificate',
  templateUrl: './view-certificate.component.html',
  styleUrls: ['./view-certificate.component.scss']
})
export class ViewCertificateComponent implements OnInit {


  constructor(private http: HttpClient, public dialogRef: MatDialogRef<GetCertificateComponent>,private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,private service:AppService,
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer,

    ) {

  }

  ngOnInit(): void {
    this.download(true)
    console.log(this.data)
    console.log(this.data)
    console.log(this.data)
  }
  getCertificate(sendToEmail = 'no'){
    this.spinner.show()
    // this.form.value['email'],this.form.value['code'],this.conference.id
    this.service.detailsCertificate(
      this.data['email'],
      this.data['certificate']['data']['code'],
      this.data['conference']['id'],
      (sendToEmail == 'yes' ? 'json' : 'download'),
      sendToEmail
    ).subscribe((response:any)=>{

      if(sendToEmail == 'yes'){
        Swal.fire({
          title: 'Success',
          text: 'Certificate sent to your email',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        this.dialogRef.close()
      }
      this.spinner.hide()
      console.log(response)
      console.log(response)
    })
  }
  fileURL
  blob
  page

  imageFromPdf
   conf(files){
    if (files.length !== 1) return;
      const file :any= files[0];
      let reader = new FileReader();
      reader.onload = (e :any) => {
        const data = atob(e.target.result.replace(/.*base64,/, ''));
        renderPDF(data);
      }

      reader.readAsDataURL(file);


    async function renderPDF(data) {
      const pdf = await PDFJSStatic.getDocument({data}).promise;
      console.log(pdf)
      for (let i = 1; i <= pdf.numPages; i++) {
        const image = document.createElement('img');
        // document.body.appendChild(image);

        const page = await pdf.getPage(i);
        const viewport = page.getViewport({scale: 2});
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({canvasContext, viewport}).promise;
        const data = canvas.toDataURL('image/png');
        image.src = data;
        image.style.width = '100%';
        image.classList.add('pdf-image');
        // saveAs(data, "test.png");
        document.getElementById('pdf').appendChild(image);
        // this.imageFromPdf = data
      }
    }


    /*

    */



    /*

    */


    return
    // saveAs pdf to image
    // const options = {
    //   density: 100,
    //   saveFilename: "untitled",
    //   savePath: "./images",
    //   format: "png",
    //   width: 600,
    //   height: 600
    // };
    // const store = this;
    // const main = async() => {
    //   console.log('Beginning Test');
    //   // const filePath = join(process.cwd(), 'relative-path-to-my-pdf.pdf');
    //   const buffer = await fetch(this.fileURL).then((res) => res.arrayBuffer());
    //   const doc :any= await pdfjsLib.PDFDocument.load(buffer);

    //   console.log(doc)


    //   console.log('PDFNet and PDF document initialized and locked');
    //   const page :any= await doc.getPage(0); // 1-based index

    //   this.page = page
    //   // save page as saveAsPng
    //   // const pngImage :any= await page.saveAsPng(options);
    //   // console.log(pngImage)

    //   // console.log(page)

    // }
    // const main2 = async() => {

    //   // const filePath = join(process.cwd(), 'relative-path-to-my-pdf.pdf');
    //   const buffer = await fetch(this.fileURL).then((res) => res.arrayBuffer());
    //   const doc :any= await pdfjsLib.PDFDocument.load(buffer);

    //   console.log(doc)



    //   const page :any= await doc.getPage(0); // 1-based index

    //   // save page as saveAsPng
    //   const pngImage :any= await page.saveAsPng(options);
    //   console.log(pngImage)



    //   // // const filePath = join(process.cwd(), 'relative-path-to-my-pdf.pdf');
    //   // const buffer = await fetch(this.fileURL).then((res) => res.arrayBuffer());
    //   // const doc :any= await pdfjsLib.PDFDocument.load(buffer);

    //   // console.log(doc)


    //   // console.log('PDFNet and PDF document initialized and locked');
    //   // const page :any= await doc.getPage(0); // 1-based index

    //   // save page as saveAsPng
    //   // const pngImage :any= await this.page.saveAsPng(options);
    //   // console.log(pngImage)
    //   // console.log(pngImage)

    //   // console.log(page)

    // }
    // main().catch((e) => console.log(e));
    // main2().catch((e) => console.log(e));
    // const pdfDoc = await PDFDocument.load(this.blob);


    // var binary_string = atob(this.blob);
    // var len = binary_string.length;
    // var bytes = new Uint8Array(len);
    // for (var i = 0; i < len; i++) {
    //     bytes[i] = binary_string.charCodeAt(i);
    // }
    // const main = async() => {
    //     console.log('Beginning Test');
    //     const doc = await PDFNet.PDFDoc.createFromBuffer(bytes.buffer);
    //     doc.initSecurityHandler();
    //     doc.lock();

    //     console.log('PDFNet and PDF document initialized and locked');
    // };
    // const options = {
    //   density: 100,
    //   saveFilename: "untitled",
    //   savePath: "./images",
    //   format: "png",
    //   width: 600,
    //   height: 600
    // };
    // const storeAsImage = fromPath(this.fileURL, options);
    // const pageToConvertAsImage = 1;

    // storeAsImage(pageToConvertAsImage).then((resolve) => {
    //   console.log("Page 1 is now converted as image");

    //   return resolve;
    // });

  }
  downloadFile(fileURL){
    var link = document.createElement('a');
    link.href = fileURL;
    link.download = this.data['conference']['title_by_app_lang'] + ' certificate' + '.pdf';
    link.dispatchEvent(new MouseEvent('click'));
    this.dialogRef.close()
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
     }

  download( view = false ){
    var mediaType = 'application/pdf';
    this.spinner.show()
    this.http.post(`${environment.apiUrl}/ConferencesSeminars/generateCertificatesNew`,
    {
      "registration_email": this.data['email'],
       "code":this.data['certificate']['data']['code'],
       "conferences_seminars_id":this.data['conference']['id'],
        "operation_type":'download',
        "send_to_email":'no'
  },

    { responseType: 'blob' }).subscribe(
        (response:any) => {
            var blob = new Blob([response], { type: mediaType });


            // this.blob = blob
            console.log(response)
            console.log(blob)
            this.blob = new Blob([new Uint8Array(response)], {type: mediaType});

            // response to file
            var file = new File([response], 'certificate.pdf', { type: mediaType });
            var fileList: FileList = { 0: file, length: 1, item: (index: number) => file } as FileList;
            this.conf(fileList)


//             var link = document.createElement('a');
// link.href = window.URL.createObjectURL(myBlob);;
// link.click();

  var fileURL:any = window.URL.createObjectURL(blob);

            this.fileURL = fileURL
console.log(fileURL)




            // console.log(blob)

            // window.open(fileURL, '_blank');
             this.spinner.hide()

                //  if(!fileURL || typeof fileURL.closed=='undefined')
                //  {
                //    Swal.fire({
                //      title: 'error!',
                //      text: `يجب تفعيل النوافذ المنبثقة`,
                //      icon: 'error',
                //    })
                //    return
                //  }
            // saveAs(blob, 'report.pdf');
        },
        (e:any) => {
          console.log(e)
          console.log(e)
          this.spinner.hide()
          Swal.fire({
            title: 'error!',
            text: 'حدث خطأ ما يرجى المحاولة مرة اخرى لاحقا',
            icon: 'error',
          })
          // throwError(e);
         }
    );
  }
}
