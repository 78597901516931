import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import AOS from 'aos';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { map } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  public index;

  statistics = [
    {name:'Events',count:4983,image:'assets/icons/Iconly-Bold-Calendar.svg'},
    {name:'Users',count:2844,image:'assets/icons/Iconly-Bold-Profile.svg'},
    {name:'Visitors',count:1095,image:'assets/icons/Group 5843.svg'},
    {name:'Partners',count:1095,image:'assets/icons/Group 5844.svg'},
    {name:'Exhibition',count:1095,image:'assets/icons/Path 3008.svg'},
    // {name:'Events',count:4983,image:'assets/icons/Iconly-Bold-Calendar.svg'},
  ]
  goals = [
    {desciption:'Establishing and qualifying an integrated team of Saudi males and females to work in the exhibition and conference market with high efficiency,',image:'assets/icons/Goals.png'},
    {desciption:'Establishing and qualifying an integrated team of Saudi males and females to work in the exhibition and conference market with high efficiency,',image:'assets/icons/Group 5833.png'},
    {desciption:'Establishing and qualifying an integrated team of Saudi males and females to work in the exhibition and conference market with high efficiency,',image:'assets/icons/Group 5834.png'},
    {desciption:'Establishing and qualifying an integrated team of Saudi males and females to work in the exhibition and conference market with high efficiency,',image:'assets/icons/Group 5836.png'},
  ]
  activities = [
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt',image:'assets/develop-images/pexels-maurício-mascaro-1376124.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt',image:'assets/develop-images/pexels-alexandr-podvalny-3274551.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt',image:'assets/develop-images/pexels-sebastian-ervi-1763067.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt',image:'assets/develop-images/pexels-maurício-mascaro-1376124.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt',image:'assets/develop-images/pexels-alexandr-podvalny-3274551.png'},
  ]
  mainSliderOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    margin:10,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  statisticsSliderOptions: OwlOptions = {

    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    autoplay: true,
    slideTransition: 'linear',
    margin:10,
    // autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  }
  parentsSliderOptions: OwlOptions = {

    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    autoplay: true,
    slideTransition: 'linear',
    // autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 4
      },
      740: {
        items: 6
      },
      940: {
        items: 7
      }
    },
    nav: false
  }
  activitiesSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    lazyLoad:true,
    autoplay:true,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }
  conferences :any

  constructor(private spinner: NgxSpinnerService,private service:AppService,private shared:SharedService) { }

  ngOnInit(): void {
    AOS.init();
    this.getIndex()
    this.getConferencesprams('previous');
  }
  getIndex(){
    this.spinner.show()
    this.service.index().pipe(map(response=>response['data'])).subscribe(response=>{
      this.index = response
      this.spinner.hide()
    })
  }
  getConferencesprams(up_comming){
    console.log(up_comming)
    console.log(up_comming)
    console.log(up_comming)
    this.spinner.show()
    this.service.conferences('yes',true,up_comming).pipe(map(response=>response['data'])).subscribe(response=>{

      this.conferences = response
      console.log(response)
      console.log(response)
      console.log(response)
      this.spinner.hide()
    })
  }
}
