import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
// import { Toast } from './alert';
import Swal from 'sweetalert2'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router,private spinner: NgxSpinnerService,private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            this.spinner.hide()
            // 🤯
            switch (err.status) {
                case 400:
                    for (const [key, error] of Object.entries(err.error.messages)) {
                            Swal.fire({
                                title: 'Validation Error!',
                                text: `${error}`,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                              })
                    }
                    break;
                case 401:
                    for (const [key, error] of Object.entries(err.error.messages)) {
                        Swal.fire({
                            title: 'Validation Error!',
                            text: `${error}`,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                          })
                }
                    // this.authenticationService.logout()
                    // Toast().fire({ icon: 'error', title:  err.error.message })
                    // this.router.navigate(['/app/login'])
                    // break;
                    // case 409:
                    //     try {
                    //         for (const [key, error] of Object.entries(err.error.errors)) {
                    //             Toast().fire({ icon: 'error', title: error })
                    //         }
                    //     } catch (error) {
                    //         Toast().fire({ icon: 'error', title: err.error.message })
                    //     }
                        break;
            
                    case 422:
                        // for (const [key, error] of Object.entries(err.error.errors)) {
                        //         Toast().fire({ icon: 'error', title: error })
                        // }
                        break;    
                case 403:
                    // Toast().fire({ icon: 'error', title: 'عفواً لقد انتهت الجلسة سيتم تسجيل الخروج تلقائياً' })
                    // this.authenticationService.logout();  
                    break;
                case 404:
                    console.log(err)
                    Swal.fire({
                        title: 'Error 404!',
                        text: err.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                      })
                        // Toast().fire({ icon: 'error', title: 'عفواً لقد انتهت الجلسة سيتم تسجيل الخروج تلقائياً' })
                        // this.authenticationService.logout();  
                    break;
                case 500:
                    Swal.fire({
                        title: 'Error 500!',
                        text: 'There is a issues with the server, please try again later',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                      })
                    // try {
                    //     for (const [key, error] of Object.entries(err.error.errors)) {
                    //         Toast().fire({ icon: 'error', title: error })
                    //     }
                    // } catch (error) {
                    //     Toast().fire({ icon: 'error', title: 'حدث خطأ في الخادم . حاول لاحقًا' })
                    // }
                    break;
                default:
                    // Toast().fire({ icon: 'error', title:  err.error.message })
                    break;
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}