import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  public user

  constructor(    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    private sanitizer: DomSanitizer,
    private service:AppService,
    private authentication:AuthenticationService,
    private http:HttpClient
) {
  this.authentication.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
}

  ngOnInit(): void {
    this.generate()
  }
  // conferences_seminars_member_id: this.user.user.id,
  // conferences_seminars_id: this.conferenceId
  fileURL
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
     }
  generate(){
  //   return this.http.post(`${environment.apiUrl}/ConferencesSeminars/generateCertificates?send_to_mail=${send_to_mail}`,{
  //     "conferences_seminars_member_id": conferences_seminars_member_id,
  //      "conferences_seminars_id":conferences_seminars_id
  // })


    var mediaType = 'application/pdf';
    console.log('==================####=================')
    this.http.post(`${environment.apiUrl}/ConferencesSeminars/generateCertificates?send_to_mail=yes`, {
      conferences_seminars_member_id : this.options['data']['conferences_seminars_member_id'],
      conferences_seminars_id : this.options['data']['conferences_seminars_id']

    }, { responseType: 'blob' }).subscribe(
        (response) => {
            var blob = new Blob([response], { type: mediaType });
            console.log(blob)
            var fileURL:any = window.URL.createObjectURL(blob);
            this.fileURL = fileURL
            window.open(fileURL, '_blank');

                 if(!fileURL || typeof fileURL.closed=='undefined')
                 {
                   Swal.fire({
                     title: 'error!',
                     text: `يجب تفعيل النوافذ المنبثقة`,
                     icon: 'error',
                   })
                   return
                 }
            // saveAs(blob, 'report.pdf');
        },
        (e:any) => {
          console.log(e)
          console.log(e)
          // throwError(e);
         }
    );
    // this.service.generateCertificate(this.options['data']['conferences_seminars_member_id'],this.options['data']['conferences_seminars_id'],).
    // subscribe((response:any)=>{
    //   const fileURL = URL.createObjectURL(response);
    //   console.log(fileURL)
    //   window.open(fileURL, '_blank');
    //   console.log(new Blob([response], { type: 'application/pdf' }))
    //   return new Blob([response], { type: 'application/pdf' });


    //   // var file = new Blob([response.blob()], {type: 'application/pdf'});
    //   // var fileURL = window.URL.createObjectURL(file);
    //   // console.log(fileURL)
    //   // console.log(fileURL)
    //   // console.log(fileURL)
    //   // window.open(fileURL);

    // })
  }
}
