import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
declare var pannellum: any;
import AOS from 'aos';
import { AppService } from 'src/app/app.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
declare const embedpano:any;

@Component({
  selector: 'app-virtual-tour',
  templateUrl: './virtual-tour.component.html',
  styleUrls: ['./virtual-tour.component.scss','../conferences/conferences.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class VirtualTourComponent implements OnInit {
  conferenceId = this.route.snapshot.paramMap.get('conferenceId')

  conference
  recommended = []
  ngAfterViewInit() {
    embedpano({
      swf: 'assets/krpano/tour.swf',
      xml: 'assets/krpano/tour.xml',
      target: 'pano',
      html5: 'auto',
      mobilescale: 1.0,
      passQueryParameters: true,
      logkey:false
      });
    }
  constructor(private spinner:NgxSpinnerService,private service:AppService,private route:ActivatedRoute) { }

  ngOnInit(): void {
    AOS.init();
    this.getConference(this.conferenceId)
    // pannellum.viewer('panorama', {
    //   "type": "equirectangular",
    //   "panorama":  "https://pannellum.org/images/alma.jpg",
    //   "autoLoad": true,
    //   "compass": false,

    // });
  }
  getConference(conferenceId){
    this.spinner.show()
    this.service.conferenceShowById(conferenceId).pipe(map(response=>response['data'])).subscribe((response:any)=>{
      // console.clear()
      console.log(response.conferences_seminars)
      this.conference = response.conferences_seminars
      this.recommended = response.recomend_conferences_seminars
      console.log(response.recomend_conferences_seminars)
      this.spinner.hide()
    })
  }

}
