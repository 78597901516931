import { AuthenticationService } from './../../../services/authentication.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-conference-registration',
  templateUrl: './conference-registration.component.html',
  styleUrls: ['./conference-registration.component.scss']
})
export class ConferenceRegistrationComponent implements OnInit{
  /*
  * 	 path => import { ActivatedRoute } from '@angular/router';
  * 	 param => public route: ActivatedRoute
  */
  public conferenceId = this.route.snapshot.paramMap.get('id')
  public email = this.route.snapshot.paramMap.get('email')

  // @Input() conferenceId;
  // @Input() email;

  public form: FormGroup;
  unsubcribe: any
  public loading = false;

  public fields = []

  // public fields: any[] = [
  //   {
  //     type: 'text',
  //     name: 'firstName',
  //     label: 'First Name',
  //     value: '',
  //     required: true,
  //   },
  //   // {
  //   //   type: 'text',
  //   //   name: 'lastName',
  //   //   label: 'Last Name',
  //   //   value: '',
  //   //   required: true,
  //   // },
  //   // {
  //   //   type: 'text',
  //   //   name: 'email',
  //   //   label: 'Email',
  //   //   value: '',
  //   //   required: true,
  //   // },

  //   // {
  //   //   type: 'file',
  //   //   name: 'picture',
  //   //   label: 'Picture',
  //   //   required: true,
  //   //   onUpload: this.onUpload.bind(this)
  //   // },
  //   // {
  //   //   type: 'dropdown',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'in', label: 'India' },
  //   //     { key: 'us', label: 'USA' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'radio',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'm', label: 'Male' },
  //   //     { key: 'f', label: 'Female' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'checkbox',
  //   //   name: 'hobby',
  //   //   label: 'Hobby',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'f', label: 'Fishing' },
  //   //     { key: 'c', label: 'Cooking' }
  //   //   ]
  //   // }
  // ];
  countries = []
  constructor(    private translocoService: TranslocoService,

    // get current transloco language


    private auth:AuthenticationService,public route: ActivatedRoute,private service:AppService,private modalService: BsModalService) {
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.fields))
    })

    this.unsubcribe = this.form.valueChanges.subscribe((update) => {
      this.fields = JSON.parse(update.fields);
    });
  }
  getCountries(){
    this.auth.countries().pipe(map(response=>response['data'])).subscribe(countries=>{
      // console.log(countries)
      // this.countries = countries
      countries.forEach(element => {
        this.countries.push({key:element.id,label:element.name_by_app_lang})
      });
      console.log(this.countries)
      this.getConferencesSeminarsWithFields()

    })
  }
  ngOnInit() {
    // console.log(this.conferenceId)
    // console.log(this.conferenceId)
    // console.log(this.conferenceId)
    // console.log(this.conferenceId)
    this.getCountries()


  }
  getConferencesSeminarsWithFields(){
    this.loading = true


    this.service.getConferencesSeminarsWithFields(this.conferenceId).pipe(map(res=>res['data']['form_inputs'])).subscribe(Response=>{
      var arr = []

      // static fields
      // country_id

      // "DaymanicForm":{
      //   "country_code" : "Country Code",
      //   "phone" : "Phone Number",
      //   "name":"Name",
      //   "country":"Country",
      //   "title":"Title"
      // },


      arr.push({
        type: 'select',
        name: 'title',
        label: this.translocoService.translate('DaymanicForm.title'),
        value: '',
        required: true,
        options: [
          { key: 'Mr', label: 'MR' },
          { key: 'Ms', label: 'MRS' }
        ]
      }),

      arr.push({
        type: 'text',
        name: 'name',
        label: this.translocoService.translate('DaymanicForm.name'),
        value: '',
        required: true,
      }),
      arr.push({
        type: 'text',
        name: 'sp_field',
        label: this.translocoService.translate('DaymanicForm.sp_field'),
        value: '',
        required: false,
      }),
      arr.push({
        type: 'number',
        name: 'country_code',
        label: this.translocoService.translate('DaymanicForm.country_code'),
        value: '',
        required: true,
      }),
      arr.push({
        type: 'number',
        name: 'phone',
        label: this.translocoService.translate('DaymanicForm.phone'),
        value: '',
        required: true,
      }),
      arr.push({
        type: 'select',
        name: 'country_id',
        label: this.translocoService.translate('DaymanicForm.country'),
        value: '',
        required: true,
        options: this.countries
      }),

      console.log(Response)

      Response.forEach(field => {
        console.log(field)
        if(field.virtual_name_en == "Phone"){
          return
        }
        // public fields: any[] = [
  //   {
  //     type: 'text',
  //     name: 'firstName',
  //     label: 'First Name',
  //     value: '',
  //     required: true,
  //   },
  //   // {
  //   //   type: 'text',
  //   //   name: 'lastName',
  //   //   label: 'Last Name',
  //   //   value: '',
  //   //   required: true,
  //   // },
  //   // {
  //   //   type: 'text',
  //   //   name: 'email',
  //   //   label: 'Email',
  //   //   value: '',
  //   //   required: true,
  //   // },

  //   // {
  //   //   type: 'file',
  //   //   name: 'picture',
  //   //   label: 'Picture',
  //   //   required: true,
  //   //   onUpload: this.onUpload.bind(this)
  //   // },
  //   // {
  //   //   type: 'dropdown',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'in', label: 'India' },
  //   //     { key: 'us', label: 'USA' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'radio',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'm', label: 'Male' },
  //   //     { key: 'f', label: 'Female' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'checkbox',
  //   //   name: 'hobby',
  //   //   label: 'Hobby',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'f', label: 'Fishing' },
  //   //     { key: 'c', label: 'Cooking' }
  //   //   ]
  //   // }
  // ];

        if(field.type == 'select' || field.type == 'checkbox' || field.type == 'radio'){
          var values = [];
          try {
            (field.value).split('**').forEach(function(val,index,array){
              values.push({ key: array[index], label: array[index] })
            })
          } catch (error) {
            console.error(field,error)
            // console.error(error)
          }
          arr.push({
            type: field.type,
            name: field.program_name,
            label: field.current_virtual_name,
            value: field.value,
            required: Number(Boolean(field.is_optional)),
            options:values
          })
        }else if(field.type == 'file'){
    //            type: 'file',
    //   name: 'picture',
    //   label: 'Picture',
    //   required: true,
    //   onUpload: this.onUpload.bind(this)
    // },
          arr.push({
            type: field.type,
            name: field.program_name,
            label: field.current_virtual_name,
            value: field.value,
            required: Number(Boolean(field.is_optional)),
            onUpload:this.onUpload.bind(this)
          })
        }

        else{
          arr.push({
            type: field.type,
            name: field.program_name,
            label: field.current_virtual_name,
            value: field.value,
            required: Number(Boolean(field.is_optional)),
          })
        }

      });
      this.fields = arr
      // console.clear()
      // console.log(this.fields)
      this.getFields()
      this.loading = false


    })
  }
  onUpload(e) {
    console.log(e);

  }

  getFields() {

    return this.fields;
  }

  ngDistroy() {
    this.unsubcribe();
  }

}
