import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CertificateComponent } from '../certificate/certificate.component';
import { ReviewComponent } from '../review/review.component';
import { ViewCertificateComponent } from '../view-certificate/view-certificate.component';

@Component({
  selector: 'app-get-certificate',
  templateUrl: './get-certificate.component.html',
  styleUrls: ['./get-certificate.component.scss']
})
export class GetCertificateComponent implements OnInit {
   public submitted = false;
   public form:FormGroup;
   @Input() email: string

   conference:any
   public user
     public modalRef: BsModalRef  ;


     public options: ModalOptions

   constructor( public dialog: MatDialog, public dialogRef: MatDialogRef<GetCertificateComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private formbuilder:FormBuilder,private authentication:AuthenticationService,private spinner:NgxSpinnerService,private service:AppService,private modalService: BsModalService) {
    this.authentication.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)

    console.log(this.data)
    this.conference = this.data['conference']
    this.email = this.data['email']

      //  get config from ngx bs modal

//        this.options = this.modalService.config;
// console.log(this.options.initialState)
// this.email = this.options.initialState['email']
// this.conference = this.options.initialState['conference']
      //  console.log(this.modalRef)
      //  console.log(this.options)
      // //  this.modalRef.content.date.subscribe(date => {
      // //   console.log(date);
      // // });
      // this.bsModalRef.content.action.take(1).subscribe((value) => {
      //   console.log(value) // here you will get the value
      //   });

   }
   ngOnInit(): void {



     console.log()
     this.form = this.formbuilder.group({
      email:[this.email,[Validators.required,Validators.email]],
      code:['',Validators.required],
    })

    //  if(this.user){
    //   this.form = this.formbuilder.group({
    //     email:[this.email,[Validators.required,Validators.email]],
    //     code:['',Validators.nullValidator],
    //   })
    //  }else{
    //   this.form = this.formbuilder.group({
    //     email:[this.email,[Validators.required,Validators.email]],
    //     code:['',Validators.required],
    //   })
    //  }



   }
   get f() {return this.form.controls}
   errorMessages = []
   submit(){
    this.errorMessages = []
     this.submitted = true;
     if (this.form.invalid) {
       // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
       return
     }
    this.spinner.show()

    // check if user is registered in conference
     this.service.checkConferenceJoinEmail(this.form.value['email'],this.conference.id).pipe(map(res=>res['data']['result'])).subscribe((response:any)=>{
          console.log(response)
          if(response['is_joined'] == false){
            this.errorMessages.push('You are not registered in this conference')
            this.spinner.hide()

          }
          // if(response['is_paid'] == false){
          //   this.errorMessages.push('You are not paid for this conference')
          // this.spinner.hide()


          // }
          if( response['is_joined'] == true){
            this.errorMessages = []
            this.getDetailsCertificate()
          }



     })

     return
    //


     // alert(JSON.stringify({...this.form.value}))
   }
   getDetailsCertificate(){
    this.spinner.show()
    this.service.detailsCertificate(this.form.value['email'],this.form.value['code'],this.conference.id).subscribe((response:any)=>{
      this.spinner.hide()
      // if(res.member_record.is_rated == 1){
      //   // قام بالتقييم من  قبل

      // }else{
      //   // لم يقم بالتقييم من قبل
      // }
      if(response.data.is_rated == 1){
         this.dialogRef.close();
         const dialogRef = this.dialog.open(ViewCertificateComponent, {
           width: '600px',
           maxHeight: '90vh',
           data: {
             certificate: response,
             conference: this.conference,
             email: this.form.value['email'],

           }
         });
         dialogRef.afterClosed().subscribe(result => {
           console.log('The dialog was closed');
         });




      }else{
           // لسه معملش فيد باك
           console.log(response)
           // open ReviewComponent in angualr material dialog
           this.dialogRef.close();
           const dialogRef = this.dialog.open(ReviewComponent, {
             width: '700px',
             maxHeight: '90vh',

             data: {
               certificate: response,
               conference: this.conference,
               email: this.form.value['email'],
             }
           });
           dialogRef.afterClosed().subscribe(result => {
             console.log('The dialog was closed');
           });

      }


      // const initialState :any= {
      //     certificate: response['data'],
      //     conference: this.conference,
      //     email: this.form.value['email'],
      // };
      // const config: ModalOptions = {
      //   class: 'modal-md',
      //   initialState,
      // };
      // // close this modal and open ReviewComponent
      // // this.modalService.hide(1)



      // this.modalRef = this.modalService.show(ReviewComponent, config);
      // this.modalRef.content.closeBtnName = 'Close';



      // this.modalRef = this.modalService.show(ReviewComponent, {
      //   initialState: {
      //     certificate: response['data'],
      //     conference: this.conference
      //   },
      //   class: 'modal-lg',
      //   backdrop: 'static',
      //   keyboard: false
      // });
      // this.modalRef.content.action.take(1).subscribe((value) => {
      //   console.log(value) // here you will get the value
      //   });

      this.spinner.hide()
    })
   }
}
