import { Injectable, ViewChild } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { distinctUntilChanged } from "rxjs/operators";
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinner } from "ngx-spinner/lib/ngx-spinner.enum";

@Injectable()
export class Language {
  // @ViewChild("blackSpinner") blackSpinner: NgxSpinner;

    constructor(private translocoService:TranslocoService,private spinner:NgxSpinnerService){
    }

    switchLang(lang: string) {

             // if(confirm('The page will reload to show the data in the language you selected')){
            
        // }
        this.translocoService.setActiveLang(lang);
            this.setAppDirection(lang);
            window.location.reload()
        
    }
    selectedLanguage(){

        const lang = (localStorage.getItem('lang') || 'en');
        this.setAppDirection(lang);
        // registerLocaleData(lang);

        // set the saved language from local storage.
        this.translocoService.setActiveLang(lang);
        // persist the current language in local storage.
        this.translocoService.langChanges$.pipe(distinctUntilChanged()).subscribe(lang => localStorage.setItem('lang', lang));
      }
      getSelectedLanguage():any{
        return (localStorage.getItem('lang') || 'en');
      }
      setAppDirection(lang){
        switch (lang) {
            case 'ar':
              // document.body.style.direction= "rtl";
              document.body.setAttribute('dir', 'rtl')
              break;
            default:
              // document.body.style.direction= "ltr";
              document.body.setAttribute('dir', 'ltr')
              break;
          }
      }
}