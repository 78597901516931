import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { Language } from './changeLanguage';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private readonly injector: Injector,private authenticationService: AuthenticationService,private language:Language) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.access_token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            console.log(currentUser)
     
            request = request.clone({
                setHeaders: {
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${currentUser.access_token}`,
                    "Accept-Language" : this.language.getSelectedLanguage()
                },
                setParams:{
                    // ...(Location != null) && {'user_lat': Location?.center[0]},
                    // ...(Location != null) && {'user_lng': Location?.center[1]},
                    // place_name: Location.place_name,
                }   
            });
        }else{
            request = request.clone({
                setHeaders: {
                    "Accept-Language" : this.language.getSelectedLanguage()
                }
            });
        }

        return next.handle(request);
    }
}