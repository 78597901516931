import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import AOS from 'aos';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss','../home/home.component.scss','../conferences/conferences.component.scss']
})
export class BlogComponent implements OnInit {
  events = [
    "https://f.hubspotusercontent30.net/hubfs/6889138/AV_Images-May2021/Images/corporate-popup2.jpg",
    "https://velocityavs.com/wp-content/uploads/2016/10/Draft-Kings-1-1024x684.jpg",
    "https://www.theshowbusiness.com/wp-content/uploads/2016/08/Corporate-Main-page-pic.jpg",
  ]
  activities = [
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',image:'assets/develop-images/pexels-maurício-mascaro-1376124.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',image:'assets/develop-images/pexels-alexandr-podvalny-3274551.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',image:'assets/develop-images/pexels-sebastian-ervi-1763067.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',image:'assets/develop-images/pexels-maurício-mascaro-1376124.png'},
    {title:'Lorem ipsum dolor sit amet',desciption:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',image:'assets/develop-images/pexels-alexandr-podvalny-3274551.png'},
  ]
  activitiesSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    margin:0,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
