import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  footer
  constructor(private spinner: NgxSpinnerService,private shared:SharedService) { }

  ngOnInit(): void {
    this.getFooterData()
  }
  getFooterData(){
    
    this.spinner.show()
    this.shared.footer().pipe(map(response=>response['data'])).subscribe(response=>{
      console.log(response)
      this.footer = response
      // this.index = response
      this.spinner.hide()
    })
  }

}
