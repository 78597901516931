import { Component, ContentChildren, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChangeDetectorRef, AfterContentChecked} from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Language } from 'src/app/helpers/changeLanguage';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }],
  encapsulation:ViewEncapsulation.None,

})
export class NavbarComponent implements OnInit {
  showMobileNav = false;
  mobileNavAnimated = false;
  selectedlanguage;
  user
  constructor(private cdref: ChangeDetectorRef,public language: Language,private authentication:AuthenticationService) {
    this.selectedlanguage = this.language.getSelectedLanguage()
    this.authentication.currentUser.subscribe(currentUserSubject => { this.user = currentUserSubject})
  }

  ngOnInit(): void {

  }
  changeLanguage(lang){
    this.language.switchLang(lang)
    this.selectedlanguage = lang
  }
  logout(){
    this.authentication.logout()
  }
  getAvatar(){
    return `https://ui-avatars.com/api/?name=${this.user.user.fname_en} ${this.user.user.lname_en}`
  }
    @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    var element = document.querySelector('header');

    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('sticky');
    } else {
      element.classList.remove('sticky');
    }
  }

}
