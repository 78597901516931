import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password, 
@Component({
    selector: 'file',
    template: `
      <div [formGroup]="form">
      <!-- <input type="file" multiple="" (change)="handleUpload($event,field.name)">  -->

      <div class="form-group">
    <input type="file" class="form-control"   (change)="handleUpload($event,field.name)">
  </div>


          <!-- {{field|json}} -->
        <!-- <div *ngIf="!form.controls[field.name].value" class="drop-container dropzone" dropZone (hovered)="toggleHover($event)"
          (dropped)="field.onUpload($event)" [class.hovering]="isHovering">
          <p class="m-0">
            Drag a file here or
            <label class="upload-button">
              <input type="file" multiple="" (change)="handleUpload($event,field.name)"> browse
            </label>
            to upload.
          </p>
        </div> -->
        <!-- <div *ngIf="form.controls[field.name].value">
          <button type="button" class="btn btn-primary" (click)="form.controls[field.name].setValue(null)">Clear</button>
          <div class="card">
            <img class="card-img-top" [src]="form.controls[field.name].value['base64']">
          </div>
        </div> -->
      </div> 
    `,
    // styles:[
    //   `
    //   .drop-container {
    //     background: #fff;
    //     border-radius: 6px;
    //     height: 150px;
    //     width: 100%;
    //     box-shadow: 1px 2px 20px hsla(0,0%,4%,.1);
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border: 2px dashed #c0c4c7;
    //   }
    //   p {
    //     font-size: 16px;
    //     font-weight: 400;
    //     color: #c0c4c7; 
    //   }
    //   .upload-button {
    //     display: inline-block;
    //     border: none;
    //     outline: none;
    //     cursor: pointer;
    //     color: #A69769;
    //   }
    //   .upload-button input {
    //     display: none;
    //   }
    //   .dropzone { 
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     flex-direction: column; 
    //     border-radius: 5px;
    //     background: white;
    //     margin: 10px 0;
    //   }
    //   .dropzone.hovering {
    //       border: 2px solid #f16624;
    //       color: #dadada !important;
    //   }
    //   progress::-webkit-progress-value {
    //     transition: width 0.1s ease;
    //   }
    //   `
    // ]
})
export class FileComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
  
    constructor() {

    }

    ngOnChange(){
      console.log(this.field.value);
      // this.field.value.
    }
    handleUpload(event,name) {
        console.log(event,name)
        // field.value = $event.target.files[0]
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            file['base64'] = reader.result
            this.form.controls[name].setValue(file);
        };
    }
}