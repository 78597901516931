import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password,
@Component({
    selector: 'textbox',
    template: `
      <div [formGroup]="form">
        <input *ngIf="!field.multiline && field.type != 'email'" [attr.type]="field.type" [attr.required]="field.required" class="form-control"  [id]="field.name" [name]="field.name" [formControlName]="field.name">
        <input *ngIf="!field.multiline && field.type == 'email'"    [attr.required]="field.required" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"  attr.type="number" class="form-control"  [id]="field.name" [name]="field.name" [formControlName]="field.name">
        <!-- <input *ngIf="!field.multiline && field.type == 'file'"
        (change)="fileChangeEvent($event.target.files[0],field.name)" type="file" class="form-control"  [id]="field.name" [name]="field.name" [formControlName]="field.name"> -->

        <textarea *ngIf="field.multiline" [class.is-invalid]="isDirty && !isValid" [attr.required]="field.required" [formControlName]="field.name" [id]="field.name"
        rows="9" class="form-control" [placeholder]="field.placeholder"></textarea>


      </div>
    `
})
export class TextBoxComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }

    constructor() {

    }
    fileChangeEvent(file,name){
        this.form.controls[name].setValue(file);
    }
}
