import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './../../services/authentication.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CertificateComponent } from './certificate/certificate.component';
import AOS from 'aos';
import { map, take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicFormBuilderComponent } from './dynamic-form-builder/dynamic-form-builder.component';
import { ConferenceRegistrationComponent } from './conference-registration/conference-registration.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslocoService } from '@ngneat/transloco';
// import moment from 'moment';
import * as moment from 'moment';
import { GetCertificateComponent } from './get-certificate/get-certificate.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.scss','../conferences/conferences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConferenceComponent implements OnInit {
  conferenceId = this.route.snapshot.paramMap.get('id')
  joinStatus
  public submitted = false;
  public form:FormGroup;
  conference
  recommended = []
  public isJoined : boolean = false;
  public loadingJoinButton : boolean = false;
  private bsModalRef?: BsModalRef;
  parentsSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 4
      },
      740: {
        items: 6
      },
      940: {
        items: 7
      }
    },
    nav: false
  }
  public user
  // this.translocoService.translate('greetings', {name: 'world'}, 'ru');



  constructor(public dialog: MatDialog,private http:HttpClient,private authentication:AuthenticationService,private formbuilder:FormBuilder,private modalService: BsModalService,private spinner:NgxSpinnerService,private service:AppService,private route: ActivatedRoute,

    private translocoService: TranslocoService
    ) {
    this.authentication.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
    console.log(this.user)
    this.service.subject$.subscribe(val => {
      this.getConference(this.conferenceId)
      this.checkConferenceJoinEmail2()

    });
  }

  ngOnInit(): void {
    AOS.init();
    this.getConference(this.route.snapshot.paramMap.get('id'))
    this.form = this.formbuilder.group({
      email: [this.user && this.user.user.email, Validators.compose([Validators.required, Validators.email])]
    })
  }
  checkDate(date){
    return moment().isBefore(date, 'day');
  }
  rate(conf,form){
    const email = form.email
    const dialogRef = this.dialog.open(GetCertificateComponent, {
      maxHeight: '90vh',
      data: {
        conference:conf,
        email:email
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });

//     console.log(conf)

//     const initialState :any= {
//       conference:conf,
//       email:email
//     };
//     const config: ModalOptions = {
//       class: 'modal-md',
//       initialState,
//     };
//     this.bsModalRef = this.modalService.show(GetCertificateComponent, config);
//     this.bsModalRef.content.closeBtnName = 'Close';
//     this.bsModalRef.content.onClose = (myData) => {
//       // Do something with myData and then hide
//       this.bsModalRef.hide();
// };

  }
  getCertificate() {
    this.rate(this.conference,this.form.value)

return

  this.spinner.show()
  var mediaType = 'application/pdf';
  this.http.post(`${environment.apiUrl}/ConferencesSeminars/generateCertificates?send_to_mail=yes`, {
    conferences_seminars_member_id : this.user.user.id,
    conferences_seminars_id : this.conferenceId

  }, { responseType: 'blob' }).subscribe(
      (response) => {
          var blob = new Blob([response], { type: mediaType });
          console.log(blob)
          var fileURL :any= window.URL.createObjectURL(blob);
          // this.fileURL = fileURL
          window.open(fileURL, '_blank');

          if(!fileURL || typeof fileURL.closed=='undefined')
          {
            Swal.fire({
              title: 'error!',
              text: `يجب تفعيل النوافذ المنبثقة`,
              icon: 'error',
            })
            return
          }

  this.spinner.hide()

          // saveAs(blob, 'report.pdf');
      },
      (e:any) => {
        console.log(e)
       }
  );

  // const initialState: any = {
  //   initialState: {
  //     list: [
  //       'Open a modal with component',
  //       'Pass your data',
  //       'Do something else',
  //       '...'
  //     ],
  //     title: 'Modal with component'
  //   }
  // };
  // const initialState:any = {
  //   data: {
  //     conferences_seminars_member_id: this.user.user.id,
  //     conferences_seminars_id: this.conferenceId
  //   }
  // };
  // //
  // this.bsModalRef = this.modalService.show(CertificateComponent,initialState);
  // this.bsModalRef.content.closeBtnName = 'Close';
}
    // هياخد الشهاده من هنا
  pay(reg_id){
    this.spinner.show()
    this.service.conferencePay(this.form.value['email'],this.route.snapshot.paramMap.get('id'),reg_id).subscribe((response:any)=>{
      this.loadingJoinButton = false
      // window.location.href = response.data.link

      this.spinner.hide()
      const win = window.open(
        response.data.link,
        'Secure Payment',` height=500,
        width=400,
        left=100,
        top=100,
        resizable=yes,
        scrollbars=yes,
        toolbar=yes,
        menubar=no,
        location=no,
        directories=no,
        status=yes`);
        const timer = setInterval(() => {
          if (win.closed) {
            alert('"Secure Payment" window closed!');
            // this.router.navigate(['/conference',this.conferenceId])

            this.checkConferenceJoinEmail2()
            // this.getConference(this.route.snapshot.paramMap.get('id'))
          clearInterval(timer);
          // alert('"Secure Payment" window closed!');
        }
      }, 500);
      })
  }
  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    console.log(this.form.value)
    if (this.form.invalid) {
      // this.loadingJoinButton = false
      return
      // window.scroll({ top: 0, left: 0, behavior: 'smooth' });return
    }
if(this.checkDate(this.conference.end_date) == false){
  // وقت التسجيل انتهي
  // this.rate(this.conference,this.form.value)
  this.isJoined = true

  return
}

    // var win = window.open('https://github.com/');
    // const win = window.open(
    //   'https://www.google.com/',
    //   'Secure Payment',` height=500,
    //   width=400,
    //   left=100,
    //   top=100,
    //   resizable=yes,
    //   scrollbars=yes,
    //   toolbar=yes,
    //   menubar=no,
    //   location=no,
    //   directories=no,
    //   status=yes`);
    //   setTimeout( function() {
    //     if(!win || win.outerHeight === 0) {
    //         //First Checking Condition Works For IE & Firefox
    //         //Second Checking Condition Works For Chrome
    //         alert("Popup Blocker is enabled! Please add this site to your exception list.");
    //         window.close();
    //         return
    //     }
    // }, 25);
    // if(!win || typeof win.closed=='undefined')
    // {
    //   Swal.fire({
    //     title: 'error!',
    //     text: `يجب تفعيل النوافذ المنبثقة`,
    //     icon: 'error',
    //   })
    //   return
    // }


    this.loadingJoinButton = true

    console.log(this.form.value)


  this.checkConferenceJoinEmail()
  }
  checkConferenceJoinEmail(){
      // alert(JSON.stringify({...this.form.value}))
    // this.spinner.show()
    //
    this.service.checkConferenceJoinEmail(this.form.value['email'],this.route.snapshot.paramMap.get('id')).subscribe((response:any)=>{
      this.loadingJoinButton = false
        console.log(response)
        console.log(response)
        this.joinStatus = response
        if(response.data.result.is_joined){
          this.isJoined = true
        }else{
          const initialState :any = {
            conferenceId: this.route.snapshot.paramMap.get('id'),
            email:this.form.value['email']
          };
          const __conferenceId = document.getElementById('__conferenceId') as HTMLInputElement
          __conferenceId.value = this.route.snapshot.paramMap.get('id')

          const __email = document.getElementById('__email') as HTMLInputElement
          __email.value = this.form.value['email']

          // (document.getElementById('__conferenceId') as HTMLInputElement).value = this.form.value['email']
          // (document.getElementById('__email') as HTMLInputElement).value = this.form.value['email']

          // document.getElementById('__conferenceId').value
//           <input type="text" id="__conferenceId">
// <input type="text" id="__email" name="" id="">



          // alert(JSON.stringify(initialState))
             // Swal.fire(
          //   `success`,
          //   `Welcome back, ${response.user.name}`,
          //   `success`

          // )

          // inputOptions: {...this.conference.specializations_conference},
          localStorage.removeItem('specialization_id' )
          localStorage.removeItem('attend_type' )


          Swal.fire({
            title: this.translocoService.translate('select-specialization') ,
            input: 'select',
            inputOptions:{
              ...this.conference.specializations_conference.reduce((acc,curr)=>({...acc,[curr.id]:curr.name_by_app_lang}),{})
            },
            inputPlaceholder: this.translocoService.translate('select-specialization') ,
            showCancelButton: true,
          }).then((value:any) => {
            // return new Promise(function (resolve, reject) {
              console.log(value)
              console.log(value)
              console.log(value)
              if(value){
                localStorage.setItem('specialization_id', value.value )

                // debugger
                // console.log(this.conference.type_of_attend)

                if(this.conference.type_of_attend == 'online'){
                  Swal.fire({
                    title: this.translocoService.translate('How-to-attend-the-conference'),
                    input: 'select',
                    inputOptions:{
                      'online':this.translocoService.translate('Online') + ' '+ this.conference.specializations_conference.find(s=>s.id == value.value).online_price + ' '+ this.translocoService.translate('Currency'),
                    } ,
                  }).then((result) => {
                    console.log(result)
                    if(result.value){
                      localStorage.setItem('attend_type', result.value )

                      const email = document.getElementById('__email') as HTMLInputElement
                      const conferenceId = document.getElementById('__conferenceId') as HTMLInputElement

                      window.location.assign(`/conference-registration/${conferenceId.value}/${email.value}`);


                      // this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent, {initialState});
                    }
                  })
                }
                if(this.conference.type_of_attend == 'in_location'){
                  Swal.fire({
                    title: this.translocoService.translate('How-to-attend-the-conference'),
                    input: 'select',
                    inputOptions:{
                      'in_location':this.translocoService.translate('Offline') + ' ' + this.conference.specializations_conference.find(s=>s.id == value.value).in_location_price + ' '+ this.translocoService.translate('Currency'),
                    } ,
                  }).then((result) => {
                    console.log(result)
                    if(result.value){
                      localStorage.setItem('attend_type', result.value )

                      const email = document.getElementById('__email') as HTMLInputElement
                      const conferenceId = document.getElementById('__conferenceId') as HTMLInputElement

                      window.location.assign(`/conference-registration/${conferenceId.value}/${email.value}`);


                      // this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent, {initialState});
                    }
                  })
                }
                if(this.conference.type_of_attend == 'both'){
                  Swal.fire({
                    title: this.translocoService.translate('How-to-attend-the-conference'),
                    input: 'select',
                    inputOptions:{
                      'online':this.translocoService.translate('Online') + ' '+ this.conference.specializations_conference.find(s=>s.id == value.value).online_price + ' '+ this.translocoService.translate('Currency'),
                      'in_location':this.translocoService.translate('Offline') + ' ' + this.conference.specializations_conference.find(s=>s.id == value.value).in_location_price + ' '+ this.translocoService.translate('Currency'),
                    } ,
                  }).then((result) => {
                    console.log(result)
                    if(result.value){
                      localStorage.setItem('attend_type', result.value )

                      const email = document.getElementById('__email') as HTMLInputElement
                      const conferenceId = document.getElementById('__conferenceId') as HTMLInputElement

                      window.location.assign(`/conference-registration/${conferenceId.value}/${email.value}`);


                      // this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent, {initialState});
                    }
                  })
                }


              }
              // if (value === 'UKR') {
                // resolve('You selected: ' + value)
              // } else {
                // reject('You need to select Ukraine :)')
              // }
            // })
          })
          return
          this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent, {initialState});
          //   this.bsModalRef.content.onClose.subscribe(result => {
          //     console.log('results', result);
          // })
        }
      })
  }

  checkConferenceJoinEmail2(){
    // alert(JSON.stringify({...this.form.value}))
  // this.spinner.show()
  this.service.checkConferenceJoinEmail(this.form.value['email'],this.route.snapshot.paramMap.get('id')).subscribe((response:any)=>{
    this.loadingJoinButton = false
      console.log(response)
      console.log(response)
      this.joinStatus = response
      this.isJoined = true


    })
}
  join(){
    // conferenceId = null
    setTimeout(() => {


    }, 1000);
  }
  getConference(conferenceId){
    this.spinner.show()
    this.service.conferenceShowById(conferenceId).pipe(map(response=>response['data'])).subscribe(response=>{
      this.conference = response.conferences_seminars
      console.log(this.conference)
      this.recommended = response.recomend_conferences_seminars
      this.spinner.hide()
    })
  }


}
