import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'dynamic-form-builder',
  template:`

    <form (ngSubmit)="onSubmit.emit(this.form.value)" [formGroup]="form" class="form-horizontal">
      <div *ngFor="let field of fields">
          <field-builder [field]="field" [form]="form"></field-builder>
      </div>
      <div class="form-row"></div>
      <div class="form-group row">
        <div class="col-md-3"></div>
        <div class="col-md-12">
          <button (click)="submit()" type="submit" [disabled]="!form.valid" class="btn btn-sandal w-100">
            تسجيل
          </button>
        </div>
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Input() fields: any[] = [];
  @Input() conferenceId;
  @Input() email = this.route.snapshot.paramMap.get('email');
  form: FormGroup;
  /*
  * 	 path => import { ActivatedRoute } from '@angular/router';
  * 	 param => public route: ActivatedRoute
  */
  // public id = this.route.snapshot.paramMap.get('email')

  constructor( public route: ActivatedRoute,private router: Router,private service:AppService,private spinner:NgxSpinnerService,private modalService: BsModalService) {

  }
  conference
  getConference(conferenceId:any){
    this.spinner.show()
    this.service.conferenceShowById(conferenceId).pipe(map(response=>response['data'])).subscribe(response=>{
      this.conference = response.conferences_seminars
      console.clear()
      console.log(response.conferences_seminars)
      console.log(response.conferences_seminars)
      console.clear()
      this.spinner.hide()
    })
  }
  ngOnInit() {
    // debugger
    this.getConference(this.conferenceId)

    let fieldsCtrls = {};
    for (let f of this.fields) {
      if (f.type != 'checkbox') {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.required ? Validators.required : Validators.nullValidator);
      } else {
        let opts = {};
        for (let opt of f.options) {
          opts[opt.key] = new FormControl(opt.value);
        }
        fieldsCtrls[f.name] = new FormGroup(opts)
      }
    }
    this.form = new FormGroup(fieldsCtrls);
  }
  private bsModalRef?: BsModalRef;

  submit(){
    // debugger
    // console.log(this.conference)
    // return
      this.spinner.show()
      console.log(this.form.value)
      this.service.conferenceJoin(this.form.value,this.conferenceId,this.email).subscribe((response:any)=>{
        // debugger
        console.log(response)
          // هنا المفروض يروح للدفع
          // this.router.navigate(['/conference/payment',this.conferenceId])
          this.spinner.show()
          console.log(response.data.registration_id)
          console.log(this.form.value)
          // debugger
          if(this.conference.is_free == 1){
            Swal.fire({
              title: 'success!',
              text: `تم التسجيل بنجاح وسيتم التواصل معكم قريبا`,
              icon: 'success',
            })
            this.router.navigate(['/conference',this.conferenceId])
            return
          }
          console.log(localStorage.getItem('attend_type'))
          if(localStorage.getItem('attend_type') == 'online'){
            // debugger
            this.spinner.show()
            this.service.conferencePay(this.email,this.conferenceId,response.data.registration_id).subscribe((response111:any)=>{
              console.log(response111)
              // debugger
              // window.location.href = response111.data.link
              this.spinner.hide()
              const win = window.open(
                response111.data.link,
                'Secure Payment',` height=500,
                width=400,
                left=100,
                top=100,
                resizable=yes,
                scrollbars=yes,
                toolbar=yes,
                menubar=no,
                location=no,
                directories=no,
                status=yes`);

                // var newWin = window.open('https://www.google.com/', '_blank');

                setTimeout( function() {
                  if(!win || win.outerHeight === 0) {
                      //First Checking Condition Works For IE & Firefox
                      //Second Checking Condition Works For Chrome
                      alert("Popup Blocker is enabled! Please add this site to your exception list.");
                      win.close();
                      return
                  }
              }, 25);

                const timer = setInterval(() => {
                  if (win.closed) {
                    /*
                    * 	 path => import { Router } from '@angular/router';
                    * 	 param => private router: Router
                    */
                  alert('"Secure Payment" window closed!');
                    this.router.navigate(['/conference',this.conferenceId])

                  //   this.checkConferenceJoinEmail2()
                  //   // this.getConference(this.route.snapshot.paramMap.get('id'))
                  clearInterval(timer);
                }
              }, 500);
              })
          }else{

          Swal.fire({
            title: 'طريقة الدفع' ,
            input: 'select',
            inputOptions:{
              'online':'الدفع الالكتروني',
              'cash':'الدفع عند الوصول',
            },
            inputPlaceholder: 'طريقة الدفع' ,
            showCancelButton: true,
          }).then((value:any) => {
            if(value.value == 'online'){
              // debugger
              this.spinner.show()
              this.service.conferencePay(this.email,this.conferenceId,response.data.registration_id).subscribe((response222:any)=>{
                // debugger
                console.log(response)
                window.location.href = response222.data.link
                })


            }else{
              Swal.fire({
                title: 'success!',
                text: `تم التسجيل بنجاح وسيتم التواصل معكم قريبا`,
                icon: 'success',
              })
              this.router.navigate(['/conference',this.conferenceId])
            }
            // Swal.fire({
            //   title: 'success!',
            //   text: `The application has been sent successfully`,
            //   icon: 'success',
            // })
          })
          }


          // this.router.navigate(['/conference',this.conferenceId])

    this.spinner.hide()
    // window.location.reload()
    this.service.subject$.next(true)
    this.modalService.hide()

// Swal.fire({
//     title: 'success!',
//     text: `The application has been sent successfully`,
//     icon: 'success',
//   }).then((result) => {
//     window.location.reload()
//     this.modalService.hide()
//     /* Read more about isConfirmed, isDenied below */
//     // if (result.isConfirmed) {
//     //   Swal.fire('Saved!', '', 'success')
//     // } else if (result.isDenied) {
//     //   Swal.fire('Changes are not saved', '', 'info')
//     // }
//   })

        //   Swal.fire({
        //     title: 'success!',
        //     text: `The application has been sent successfully`,
        //     icon: 'success',
        //   })

        })
    }
}
