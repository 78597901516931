import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Language } from './helpers/changeLanguage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fexc';
  constructor(private router: Router,private language:Language){



    this.language.selectedLanguage()

    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    })

  }
}
