
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
  // @ViewChild('template') templateRef: TemplateRef<any>;
  public firstForm:FormGroup;
  public firstSubmitted = false;

  public submitted = false;
  public form:FormGroup;

  firstStep = false


  optlength = 4;
  leftTime = 30;
  optentered = []
  countdown

  constructor(private modalService: BsModalService,private route:ActivatedRoute,private router: Router,private formbuilder:FormBuilder,private service:AuthenticationService,private spinner:NgxSpinnerService) { 
    if (this.service.currentUserValue) { this.router.navigate(['/']) }
  }
  ngOnInit(): void {
    this.firstForm = this.formbuilder.group({
      email:['',Validators.required],
    })
    this.form = this.formbuilder.group({
      code:['',Validators.required],
      password:['',Validators.required],
      confirmPassword:['',Validators.required],
    })
  }
  
  // sendCode(email = this.route.snapshot.params['email']){
  //   this.spinner.show()
  //   this.service.sendCodeToEmail(email).pipe(map(response=>response['data'])).subscribe(response=>{
  //     console.log(response)
  //     this.spinner.hide()
  //   })
  // }

    // first form
    get ff() {return this.firstForm.controls}
  submitfirstForm(){
    this.firstSubmitted = true;
    if (this.firstForm.invalid) { 
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
    // alert(JSON.stringify({...this.form.value}))
    this.spinner.show()
    this.service.sendCodeToEmail(this.firstForm.value['email'],'reset').subscribe((response:any)=>{
    this.firstStep = true

      console.log(response)
      console.log(response)
      this.spinner.hide()
    
    })
  }

  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) { 
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
  
    this.spinner.show()
    const form = {
      email:this.firstForm.value['email'],
      code:this.form.value['code'],
      password:this.form.value['password'],
      c_password:this.form.value['confirmPassword'],
    }
    this.service.resetPassword(form).subscribe((response:any)=>{
      console.log(response)
      console.log(response)
      this.spinner.hide()
      this.router.navigate(["/login"])

      Swal.fire(
        `success`,
        `Password changed successfully, please login again`,
        `success`
      )
    })
  }


}
