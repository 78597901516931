import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public submitted = false;
  public form:FormGroup;

  constructor(private activatedRoute: ActivatedRoute,private router: Router,private formbuilder:FormBuilder,private service:AuthenticationService,private spinner:NgxSpinnerService) {
    if (this.service.currentUserValue) { this.router.navigate(['/']) }
  }

  ngOnInit(): void {


    // , Validators.email , Validators.pattern('/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/')
    this.form = this.formbuilder.group({
      email:['',Validators.required],
      password:['',Validators.required]
    })

  }
  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return
    }
    // alert(JSON.stringify({...this.form.value}))
    this.spinner.show()
    this.service.login(this.form.value).subscribe((response:any)=>{
      console.log(response)
      console.log(response)
      this.spinner.hide()
          // Swal.fire(
          //   `success`,
          //   `Welcome back, ${response.user.name}`,
          //   `success`

          // )
          this.activatedRoute.queryParams.subscribe(params => {
            console.log(params)
            if(params['conferenceId']){
              this.router.navigate([`/conference/${params['conferenceId']}`])
            }else{

              this.router.navigate(["/profile"])
            }
            // const email = params['email'];
            // this.form.controls['email'].setValue(email)
          });


    })
  }
}
