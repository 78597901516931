import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactUsSectionComponent } from './contact-us-section/contact-us-section.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
  declarations: [NavbarComponent, FooterComponent, ContactUsSectionComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    RouterModule,
    TranslocoModule,
    NgxMapboxGLModule
  ],
  exports: [NavbarComponent, FooterComponent,ContactUsSectionComponent],

})
export class SharedModule { }
