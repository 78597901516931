import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { Language } from 'src/app/helpers/changeLanguage';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
  public submitted = false;
  public form:FormGroup;
  public countries:any = []
  image
  public user
  selectedlanguage
  constructor(private router: Router,private language:Language,private formbuilder:FormBuilder,private service:AuthenticationService,private spinner:NgxSpinnerService) {
    // if (this.service.currentUserValue) { this.router.navigate(['/']) }
    this.service.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
    this.selectedlanguage = this.language.getSelectedLanguage()

  }
  detectImage(event) {
    const file = event.target.files[0];
    this.image = file
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.image['base64'] = reader.result;
          // this.imgAlert = false
    };
console.log(file)
    // console.log(this.image)
  }
  ngOnInit(): void {
    this.getCountries()

    this.form = this.formbuilder.group({
      firstNameAr: [this.user.user.fname_ar, [Validators.required]],
      middleNameAr: [this.user.user.mname_ar, [Validators.required]],
      lastNameAr: [this.user.user.lname_ar, [Validators.required]],
      firstName: [this.user.user.fname_en, [Validators.required]],
      middleName: [this.user.user.mname_en, [Validators.required]],
      lastName: [this.user.user.lname_en, [Validators.required]],
      // password: ['', [Validators.required]],
      // confirmPassword: ['', [Validators.required]],
      title: [this.user.user.title, [Validators.required]],
      nationality: [this.user.user.country.id, [Validators.required]],
      specialization: [this.user.user.specilaization, [Validators.required]],
      specializationNumber: [this.user.user.specilaization_organization_number, [Validators.required]],
      mobile: [this.user.user.phone_number1, [Validators.required]],
      mobile2Nd: [this.user.user.phone_number2, [Validators.nullValidator]],
    })
    console.log(this.user)

  }
  getCountries(){
    this.service.countries().pipe(map(response=>response['data'])).subscribe(countries=>{
      console.log(countries)
      this.countries = countries
    })
  }
  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return
    }
    console.log(this.form.value)
    // alert(JSON.stringify({...this.form.value}))
    const form = {
      ...this.form.value,
      // nationality:this.nationality,
      // residence:this.residence,
    }
    this.spinner.show()
    this.service.updateProfile(form,this.user.user.id,this.image).subscribe((response:any)=>{
      console.log(response)
      alert('done')
      var user = {
        ...this.user,
        user:{
          fname_ar:form.firstNameAr,
          fname_en:form.firstName,
          mname_ar:form.middleNameAr,
          mname_en:form.middleName,
          lname_ar:form.lastNameAr,
          lname_en:form.lastName,
          title:form.title,
          country:form.nationality,
          phone_number1:form.mobile,
          phone_number2:form.mobile2Nd,
          specialization:form.specilaization,
          specilaization_organization_number:form.specializationNumber,
          full_path:this.image.base64 || response.data.full_path,
          email:response.data.email,
          id:response.data.id,
        }
      }

      localStorage.setItem(`${environment.currentUserKey}`, JSON.stringify(user));
      window.location.reload()



      //   const formData: FormData = new FormData();
      // formData.append("fname_ar", form.firstNameAr);
      // formData.append("mname_ar", form.middleNameAr);
      // formData.append("lname_ar", form.lastNameAr);
      // formData.append("fname_en", form.firstName);
      // formData.append("mname_en", form.middleName);
      // formData.append("lname_en", form.lastName);
      // formData.append("email", this.user.user.email);
      // formData.append("title", form.title);
      // formData.append("nationality", form.nationality);
      // formData.append("country_id", form.nationality);
      // formData.append("specilaization", form.specialization);
      // formData.append("specilaization_organization_number", form.specializationNumber);
      // formData.append("country_code1", this.user.user.country_code1);
      // formData.append("phone_number1", form.mobile);
      // if(form.mobile2Nd){
      //   formData.append("country_code2", this.user.user.country_code2);
      //   formData.append("phone_number2", form.mobile);
      // }


        this.spinner.hide()

      // this.service.sendCodeToEmail(this.form.value['email']).pipe(map(response=>response['data'])).subscribe(response=>{
      //   this.router.navigate(["/confirm",this.form.value['email']])
      // })

      // console.log(response)
      // this.spinner.hide()
      // const loginForm = {
      //   email : this.form.value['email'],
      //   password : this.form.value['password'],
      // }
      // this.service.login(loginForm).subscribe((response:any)=>{
      //   console.log(response)
      //   console.log(response)
      //   this.spinner.hide()

      //       this.router.navigate(["/profile"])
      // })
    })
    // this.user.user
    // this.spinner.show()
    // this.service.login(this.form.value).subscribe((response:any)=>{
    //   // console.log(response)
    //   // this.spinner.hide()
    //       // Swal.fire(
    //       //   `success`,
    //       //   `Welcome back, ${response.user.name}`,
    //       //   `success`

    //       // )
    //       this.router.navigate(["/home"])
    // })
  }
  getAvatar(){
    return `https://ui-avatars.com/api/?name=${this.user.user.fname_en} ${this.user.user.lname_en}`
  }
}
