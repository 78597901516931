import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.scss']
})
export class ConferencesComponent implements OnInit {
  conferences :any
  up_comming = 'up_comming'
  constructor(private spinner:NgxSpinnerService,private service:AppService) { }

  ngOnInit(): void {
    AOS.init();
    this.getConferencesprams(this.up_comming)
    // this.getConferences()
  }
  //
  getConferences(){
    this.spinner.show()
    this.service.conferences('yes',true,null).pipe(map(response=>response['data'])).subscribe(response=>{

      console.log(response)
      console.log(response)
      console.log(response)
      console.log(response)
      this.conferences = response
      this.spinner.hide()
    })
  }
  getConferencesprams(up_comming){
    console.log(up_comming)
    console.log(up_comming)
    console.log(up_comming)
    this.up_comming = up_comming
    this.spinner.show()
    this.service.conferences('yes',true,up_comming).pipe(map(response=>response['data'])).subscribe(response=>{

      console.log(response)
      console.log(response)
      console.log(response)
      console.log(response)
      this.conferences = response
      this.spinner.hide()
    })
  }
}
